import { Link } from "react-router-dom";

function RecommendedCard({ posts }) {
  if (!posts || posts.length === 0) {
    return <p>No recommended posts available.</p>; // Fallback for empty posts
  }

  return (
    <div className="recommended-posts">
      {posts.map((post) => (
        <div className="related-post-card" key={post.id}>
          <div className="related-video-card-image">
            <Link to={`/${post.type === "image" ? "view" : "watch"}/${post.slug}`}>
              <img
                src={"https://api.skipthegames.tech/post_images/" + post.image || "/images-src/default-poster.png"} // Fallback to default image
                className="recent-post-image"
                alt={post.title || "Untitled Post"} // Fallback to generic alt text
              />
            </Link>
          </div>
          <div className="right-video-title">
            <Link to={`/${post.type === "image" ? "view" : "watch"}/${post.slug}`}>
              <p>{post.title || "Untitled Post"}</p>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RecommendedCard;
