import React from "react";
import Card from "./Card";
function PostCard({ posts }) {
  // Check if posts is an array and map through it to render each Card
  return (
    <div>
      {posts && posts.length > 0 ? (
        posts.map((post, index) => (
          <Card key={index} post={post} />
        ))
      ) : (
        <p>No posts available</p>
      )}
    </div>
  );
}

export default PostCard;
