import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton"; // Import MUI Skeleton

function Card({ post = {}, isLoading = false }) { // Use default parameters for props
  if (isLoading) {
    // Show skeletons while loading
    return (
      <div className="column" id="Column">
        <div className="video-card-container">
          <Skeleton variant="rectangular" width="100%" height={180} /> {/* Thumbnail skeleton */}
          <div className="video-card-content">
            <Skeleton variant="text" width="80%" height={30} /> {/* Title skeleton */}
            <div className="video-card-content-footer">
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="text" width="20%" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Normal card content when loading is complete
  const isVideo = post.type === "video"; // Check if the post type is video
  const imageUrl = "https://api.skipthegames.tech/post_images/" + post.image;
  return (
    <div className="column" id="Column">
      <div className="video-card-container">
        <Link to={`/${isVideo ? "watch" : "view"}/${post.slug}`}>
          <img
            src={imageUrl || "/images-src/default-poster.png"} // Use post thumbnail if available
            loading="lazy" // Enable lazy loading for performance
            alt={post.title || "Default thumbnail"} // Fallback alt text if title is missing
          />
        </Link>
        <div className="video-card-content">
          <Link to={`/${isVideo ? "video" : "image"}/${post.slug}`}>
            <span>{post.title || "Untitled Video"}</span> {/* Fallback text for title */}
          </Link>
          <div className="video-card-content-footer">
            <small>{post.username || "Anonymous"}</small> {/* Fallback text for username */}
            <small>
              <FontAwesomeIcon icon={faThumbsUp} /> {Math.round(post.reaction_percentage || 0)}%
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

// Prop type validation
Card.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string,
    thumbnail: PropTypes.string,
    username: PropTypes.string,
    reactionPercentage: PropTypes.number, // Corrected the property name and type
  }),
  isLoading: PropTypes.bool, // Loading state
};

export default Card;
