import React from "react";
import { Link } from "react-router-dom";

function Tags({ tags }) {
  return (
    <div className="tag-content" style={{ display: "flex", flexWrap: "wrap", gap: "10px"}}>
      {tags && tags.length > 0 ? (
        tags.map((tag, index) => (
          <Link
            to={`/search/${encodeURIComponent(tag)}`}
            key={index} id="Taglink">
            <span>{tag}</span>
          </Link>
        ))
      ) : (
        <p>No Tags Available</p>
      )}
    </div>
  );
}

export default Tags;
    