import React, { useState } from "react";

const FollowButton = () => {
  const [isFollowing, setIsFollowing] = useState(false);

  const handleFollowToggle = () => {
    setIsFollowing(!isFollowing);
  };

  return (
    <button onClick={handleFollowToggle} id="FollowBtn">
      {isFollowing ? "Following" : "Follow"}
    </button>
  );
};

export default FollowButton;
