import React, { useContext, useEffect, useState } from "react";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import Card from "./component/Card";
import { PostContext } from "../hook/postContext";
import AddSchema from "./Schema/AddSchema";
import Loader from "./component/LoadingPage";
import Data from "./component/Data.json";
function Images() {
  const { topic = "desi-new-videos", page = 1 } = useParams(); // Extract topic and page from URL
  const { error, setError } = useContext(PostContext); // Access context
  const [imagePosts, setImagePosts] = useState([]); // State to store image posts
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false); // State to track loading status
  const navigate = useNavigate(); // For navigation
  const [imagePagination, setImagePagination] = useState({ totalPages: 1, currentPage: 1, totalPosts: 0 });
  
  // Fetch Image Posts function
  const fetchImagePosts = async (topic, page) => {
    setError(null); // Clear previous errors
    setLoading(true);
    try {
      const response = await fetch(
        "https://api.skipthegames.tech/all-image-posts", // API endpoint for image posts
        {
          method: "POST", // POST method should be used
          headers: {
            "Content-Type": "application/json", // Make sure to send data as JSON
          },
          body: JSON.stringify({
            topic,
            page: Number(page), // Ensure the page is a number
          }),
        }
      );

      if (response.ok) {
        const data = await response.json(); // Ensure you're parsing the JSON data here
        setImagePosts(data.posts || []); // Update imagePosts state
        setImagePagination({
          totalPages: data.pagination.totalPages || 1,
          currentPage: data.pagination.currentPage || 1,
          totalPosts: data.pagination.totalPosts || 0,
        });
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      console.error("Error fetching image posts:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSetData = (topic) => {
    if (!Data[topic]) {
      console.error(`Topic "${topic}" not found in Data.json`);
      return;
    }
    setData(Data[topic]);
  };

  // Fetch posts and JSON data on topic change
  useEffect(() => {
    fetchImagePosts(topic, page);
    handleSetData(topic);
  }, [topic, page]);

  // Handling case where there might be fewer than 3 posts
  const limitedImagePosts = imagePosts.slice(0, 3);

  // Schema Data for SEO
  const IdJson = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "name": data.title || "Desi new videos hd / sd",
    "description": data.description || "Here, you can download brand-new desi (single, duo, or couple), desi porn, homemade, video calls, selfies, amateur, hardcore, and softcore videos.",
    "url": window.location.href,
    "itemListElement": limitedImagePosts.map((post, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": "https://skipthegames.tech/view/" + post.slug,
      "name": post.title || "Default Title",
      "image": "https://skipthegames.tech" + (post.image || "default-image.jpg"),
    })),
    "numberOfItems": limitedImagePosts.length,
    "publisher": {
      "@type": "Organization",
      "name": "BDSHUB - MMS",
      "logo": {
        "@type": "ImageObject",
        "url": "https://skipthegames.tech/images-src/bdshub.png"
      }
    }
  };

  // Topics List for Dynamic Buttons
  const topicsList = [
    "desi-new-videos",
    "desi-old-videos",
    "desi-new-pics",
    "desi-old-pics",
    "desi-model-webcam-girls",
    "live-streams-instragram-onlyfans",
    "world-pronster-videos"
  ];

  const handleTopicChange = (newTopic) => {
    navigate(`/images/${newTopic}/1`); // Navigate to the new topic, reset to page 1
  };

  return (
    <div>
      <AddSchema data={IdJson} post={limitedImagePosts[0]} />
      <div className="main-container">
        <div className="content-item-image">
          <div className="item-image-left">
            <h2>{data.title || " "}</h2>
            <p>{data.description || " "}</p>
            {/* Render Topic Buttons Dynamically */}
            <div className="item-button">
              {topicsList.map((topic) => (
                <button key={topic} id="Taglink" onClick={() => handleTopicChange(topic)}>
                  {topic.replace(/-/g, " ").toUpperCase()}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          {loading ? (
            <Loader /> // Show loader while fetching posts
          ) : error ? (
            <Loader /> // Show loader when there's an error
          ) : imagePosts.length > 0 ? (
            imagePosts.map((post) => <Card key={post.id} post={post} />)
          ) : (
            <div className="no-results">
              <p>No post found.</p>
            </div>
          )}
        </div>
      </div>

      {/* Pagination */}
      {imagePagination.totalPages > 1 && (
        <div className="pagination">
          <ul>
            {/* Previous Page */}
            <NavLink
              to={`/images/${topic}/${Math.max(1, imagePagination.currentPage - 1)}`}
            >
              <li className={imagePagination.currentPage === 1 ? "disabled" : ""}>&laquo;</li>
            </NavLink>

            {/* Page Numbers */}
            {Array.from({ length: Math.min(5, imagePagination.totalPages) }, (_, i) => {
              const currentPage = imagePagination.currentPage;
              const startPage = Math.max(1, currentPage - 2);
              const pageNumber = startPage + i;

              return (
                <NavLink to={`/images/${topic}/${pageNumber}`} key={pageNumber}>
                  <li className={currentPage === pageNumber ? "ActiveP" : ""}>{pageNumber}</li>
                </NavLink>
              );
            })}

            {/* Next Page */}
            <NavLink
              to={`/images/${topic}/${Math.min(imagePagination.totalPages, imagePagination.currentPage + 1)}`}
            >
              <li className={imagePagination.currentPage === imagePagination.totalPages ? "disabled" : ""}>&raquo;</li>
            </NavLink>
          </ul>
        </div>
      )}

    </div>
  );
}

export default Images;
