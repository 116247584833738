import React, { useEffect, useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Card from "./component/Card";
import Tags from "./component/Tags";
import { PostContext } from "../hook/postContext";
import AddSchema from "./Schema/AddSchema";
import VideoSliderAds from "./AdScripts/VideoSliderAds";
import Loader from "./component/LoadingPage";

function Home() {
  const { imagePosts, videoPosts, fetchImagePosts, fetchVideoPosts, tags } = useContext(PostContext);
  const [loading, setLoading] = useState(true);
  const isFetched = useRef(false); // Prevent duplicate requests

  useEffect(() => {
    const loadPosts = async () => {
      if (!isFetched.current) {
        await fetchImagePosts("desi-new-videos", 1);
        await fetchVideoPosts("desi-new-videos", 1);
        isFetched.current = true; // Mark as fetched
        setLoading(false);
      }
    };
    loadPosts();
  }, [fetchImagePosts, fetchVideoPosts]);

  const limitedVideoPosts = videoPosts ? videoPosts.slice(0, 6) : [];
  const limitedImagePosts = imagePosts ? imagePosts.slice(0, 8) : [];
  const post = limitedImagePosts[0];

  const Home = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "BDSHUB",
    "url": "https://skipthegames.tech/",
    "description": "Join our community for exclusive adult videos and content that caters to your desires. Experience pleasure like never before!",
    "publisher": {
      "@type": "Organization",
      "name": "BDSHUB - MMS",
      "url": "https://skipthegames.tech/",
      "logo": {
        "@type": "ImageObject",
        "url": "https://skipthegames.tech/images-src/bdshub.png"
      }
    },
    "inLanguage": "en",
    "keywords": tags
  };

  return (
    <div>
      <VideoSliderAds />
      <AddSchema data={Home} post={post} />
      <div className="home-page-contentner">
        <div className="content-item" id="ForTag">
          <div className="content-head">
            <strong>Popular Tags</strong>
          </div>
          <Tags tags={tags} />
        </div>

        {/* Video Posts */}
        <div className="content-item">
          <div className="content-head">
            <h3>Watch Video</h3>
            <div className="tooltip">
              <Link to="/videos">See More</Link>
              <span className="tooltiptext">Watch All Video</span>
            </div>
          </div>
          <div className="left">
            <div className="row" id="HomeRow">
              {loading ? (
                <Loader />
              ) : (
                limitedVideoPosts.map((post, key) => <Card key={key} post={post} />)
              )}
            </div>
          </div>
        </div>

        {/* Image Posts */}
        <div className="content-item">
          <div className="content-head">
            <strong>Most Recent Videos</strong>
            <div className="tooltip">
              <Link to="/images/">See More</Link>
              <span className="tooltiptext">Download Video</span>
            </div>
          </div>
          <div className="home-row">
            <div className="row" id="HomeRow">
              {loading ? (
                <Loader />
              ) : (
                limitedImagePosts.map((post, key) => <Card key={key} post={post} />)
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
