import React from "react";
import ReactDOM from "react-dom/client"; // For React 18+
import App from "./App";
import { BrowserRouter} from "react-router-dom";
import { AuthProvider } from "./hook/authContext";
import { PostProvider } from "./hook/postContext"; // Use PostProvider instead of PostContext   npm run build
import { HelmetProvider } from "react-helmet-async";
// Render the App with BrowserRouter
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <HelmetProvider>
              <AuthProvider>
                  <PostProvider>
                      <App />
                  </PostProvider>
              </AuthProvider>
          </HelmetProvider>
      </BrowserRouter>
  </React.StrictMode>
);