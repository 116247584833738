import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../hook/authContext";

function VideoPosts() {
  const location = useLocation();
  const navigate = useNavigate();
  const postToEdit = location.state?.post || null; // Extract post data if editing
  const isEditing = !!postToEdit; // Determine if editing
  const { user } = useContext(AuthContext);

  // State variables
  const [title, setTitle] = useState(postToEdit?.title || "");
  const [topic, setTopic] = useState(postToEdit?.topic || "");
  const [imagePreview, setImagePreview] = useState(
    postToEdit?.image ? `https://api.skipthegames.tech/post_images/${postToEdit.image}` : null
  );
  const [imageFile, setImageFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(
    postToEdit?.video ? `https://api.skipthegames.tech/post_videos/${postToEdit.video}` : null
  );
  const [videoFile, setVideoFile] = useState(null);
  const [tags, setTags] = useState(postToEdit?.tags?.split(",") || []);
  const [input, setInput] = useState("");
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0); // Progress state
  useEffect(() => {
    if (isEditing && postToEdit) {
      // Pre-fill state values when editing
      setTitle(postToEdit.title || "");
      setTopic(postToEdit.topic || "");
      setTags(postToEdit.tags.split(",") || []);

    }
  }, [isEditing, postToEdit]);

  const validateFile = (file, type) => {
    const maxSize = type === "image" ? 5 * 1024 * 1024 : 50 * 1024 * 1024; // 5MB for image, 50MB for video
    if (!file) return "No file selected.";
    if (type === "image" && !file.type.startsWith("image/")) return "Please upload a valid image.";
    if (type === "video" && !file.type.startsWith("video/")) return "Please upload a valid video.";
    if (file.size > maxSize) return `File size exceeds the ${maxSize / (1024 * 1024)}MB limit.`;
    return null;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const errorMessage = validateFile(file, "image");
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    setImageFile(file);
    const reader = new FileReader();
    reader.onloadend = () => setImagePreview(reader.result);
    reader.readAsDataURL(file);
    setError(null);
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    const errorMessage = validateFile(file, "video");
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    setVideoFile(file);
    const reader = new FileReader();
    reader.onloadend = () => setVideoPreview(reader.result);
    reader.readAsDataURL(file);
    setError(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && input.trim()) {
      e.preventDefault();
      const newTag = input.trim();
      if (!tags.includes(newTag)) {
        setTags((prevTags) => [...prevTags, newTag]);
      }
      setInput("");
    }
  };

  const removeTag = (index) => setTags((prevTags) => prevTags.filter((_, i) => i !== index));

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate inputs
    if (!title || !topic || !imageFile || !videoFile) {
      setError("Please fill in all fields, including the image and video.");
      return;
    }
  
    setError(null);
  
    // Create FormData object and append the necessary fields
    const formData = new FormData();
    formData.append("title", title);
    formData.append("topic", topic);
    formData.append("user_id", user.id);
    formData.append("tags", tags.join(","));
    formData.append("image", imageFile);
    formData.append("video", videoFile);
    if (isEditing && postToEdit.id) formData.append("id", postToEdit.id);
  
    try {
      // Determine the URL based on the editing state
      const url = isEditing
        ? "https://api.skipthegames.tech/update-video-post"
        : "https://api.skipthegames.tech/create-video-post";
  
      // Create a new XMLHttpRequest for tracking progress
      const xhr = new XMLHttpRequest();
      xhr.open(isEditing ? "PUT" : "POST", url, true);
  
      // Track progress
      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100;
          setUploadProgress(progress); // Update progress state
        }
      });
  
      // Handle response
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          alert(isEditing ? "Post updated successfully!" : "Post created successfully!");
          navigate("/dashboard/manage-post");
        } else {
          const response = JSON.parse(xhr.responseText);
          setError(response.message || "An error occurred.");
          console.error("Error response:", response);
        }
      };
  
      xhr.onerror = () => {
        setError("Something went wrong. Please try again.");
      };
  
      // Send the request
      xhr.send(formData);
    } catch (error) {
      console.error("Error during form submission:", error);
      setError("Something went wrong. Please try again.");
    }
  };
  

  return (
    <section id="video-post" className="section">
      <div className="add-post-header">
        <h4>{isEditing ? "Edit Your Video Post" : "Post Your Video Content"}</h4>
        {!isEditing && <small>Your post will require moderator approval.</small>}
      </div>
      <div className="container">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="image">Thumbnail</label>
              <input
                name="image"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Preview" />
                </div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="video">Video</label>
              <input
                name="video"
                type="file"
                accept="video/*"
                onChange={handleVideoChange}
              />
              {videoPreview && (
                <video controls>
                  <source src={videoPreview} type="video/mp4" />
                </video>
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="topic">Topic</label>
            <select value={topic} onChange={(e) => setTopic(e.target.value)} required>
              <option value="">Select a topic</option>
              <option value="desi-new-videos">Desi New Videos HD / SD</option>
              <option value="desi-old-videos">Desi Old Videos HD / SD</option>
              <option value="desi-new-pics">Desi New Pics HD / SD</option>
              <option value="desi-old-pics">Desi Old Pictures HD / SD</option>
              <option value="desi-model-webcam-girls">Desi Model | Webcam Girls</option>
              <option value="live-streams-instragram-onlyfans">Live Streams | Instagram | OnlyFans</option>
              <option value="world-pronster-videos">World Pronster Videos</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="tags">Tags</label>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Type a tag and press Enter"
            />
            <div className="tag-container">
              {tags.map((tag, index) => (
                <span key={index} className="tag" onClick={() => removeTag(index)}>
                  {tag} &times;
                </span>
              ))}
            </div>
          </div>

          {error && <div className="error">{error}</div>}
          {/* Progress Bar */}
          {uploadProgress > 0 && uploadProgress < 100 && (
                  <div className="progress-container">
                    <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                  </div>
                )}
          <div className="form-group">
            <button id="PostButton" type="submit">{isEditing ? "Update Post" : "Publish Post"}</button>
          </div>
        </form>
         
      </div>
    </section>
  );
}

export default VideoPosts;
