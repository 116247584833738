import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function AllPostCard({ post, onPostAction }) {
  const { id, image, title, view, type } = post;
  const navigate = useNavigate();

  // Determine post type and status
  const isVideo = type === "video"; // Check if post type is video
  const isPending = view === "pending"; // Assuming `view` indicates pending status
  const imageUrl =
    image
      ? `https://api.skipthegames.tech/post_images/${image}`
      : "./images-src/default-poster.png"; // Fallback image URL

  const handleEdit = () => {
    const targetPath = isVideo
      ? "/dashboard/video-post"
      : "/dashboard/create-post";
    navigate(targetPath, { state: { post } });
  };

  const renderActionButtons = () => (
    <>
      <button
        className="edit-btn"
        onClick={handleEdit}
        aria-label={`Edit post ${title}`}
      >
        Edit
      </button>
      <button
        className="delete-btn"
        onClick={() => onPostAction(id, "delete", type)}
        aria-label={`Delete post ${title}`}
      >
        Delete
      </button>
      {isPending && (
        <button
          className="approve-btn"
          onClick={() => onPostAction(id, "approve", type)}
          aria-label={`Approve post ${title}`}
        >
          Approve
        </button>
      )}
    </>
  );

  return (
    <div className="post-item" data-id={id}>
      <div className="post-item-header">
        <div className="image">
          <img src={imageUrl} alt={title || "Post Image"} />
        </div>
        <div className="header-text">
          <p>{title || "Untitled Post"}</p>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="post-actions">{renderActionButtons()}</div>
    </div>
  );
}

AllPostCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string, // Image URL or null
    title: PropTypes.string, // Title or undefined
    view: PropTypes.string, // Assuming this indicates pending status
    type: PropTypes.string.isRequired, // 'image' or 'video'
  }).isRequired,
  onPostAction: PropTypes.func.isRequired,
};

export default AllPostCard;
