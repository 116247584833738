import React from 'react';

function Logout() {
  const handleLogout = () => {
    // Perform logout action here
  };

  return (
    <section id="logout" className="section">
      <h1>Logout</h1>
      <button onClick={handleLogout}>Confirm Logout</button>
    </section>
  );
}

export default Logout;
