import videojs from 'video.js';

const Plugin = videojs.getPlugin('plugin');

// Define the Custom VAST Plugin
class VastAdsPlugin extends Plugin {
  constructor(player, options) {
    super(player, options);
    this.options = videojs.mergeOptions(
      {
        adTagUrl: '', // URL of the VAST XML
        debug: false, // Enable debugging logs
        skipOffset: 5, // Seconds after which the ad can be skipped
      },
      options
    );

    this.hasPlayedAd = false; // Flag to track if the ad has played
    this.originalSrc = player.currentSrc(); // Store the original source
    this.setupEventListeners();
  }

  setupEventListeners() {
    this.player.on('play', () => {
      if (!this.hasPlayedAd) {
        this.hasPlayedAd = true;
        this.loadAndPlayAd();
      }
    });
  }

  async loadAndPlayAd() {
    const adTagUrl = this.options.adTagUrl;

    if (!adTagUrl) {
      this.log('No VAST ad tag URL provided');
      return;
    }

    try {
      this.log('Fetching VAST ad...');
      const vastResponse = await this.fetchVastXml(adTagUrl);
      const mediaFileUrl = this.parseVastResponse(vastResponse);

      if (mediaFileUrl) {
        this.playAd(mediaFileUrl);
      } else {
        this.log('No suitable media file found in VAST response.');
      }
    } catch (error) {
      this.log('Error fetching or parsing VAST ad:', error);
    }
  }

  async fetchVastXml(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseXML);
        } else {
          reject(new Error(`Failed to fetch VAST XML: ${xhr.statusText}`));
        }
      };
      xhr.onerror = () => reject(new Error('Network error while fetching VAST XML'));
      xhr.send();
    });
  }

  parseVastResponse(vastXml) {
    const mediaFiles = vastXml.getElementsByTagName('MediaFile');
    for (const mediaFile of mediaFiles) {
      const type = mediaFile.getAttribute('type');
      const url = mediaFile.textContent.trim();

      if (type === 'video/mp4' && url) {
        return url; // Return the first compatible media file URL
      }
    }
    return null;
  }

  playAd(mediaFileUrl) {
    const player = this.player;

    this.log('Playing VAST ad:', mediaFileUrl);

    player.pause();
    player.src({ src: mediaFileUrl, type: 'video/mp4' });
    player.play();

    this.addSkipButton();

    player.one('ended', () => {
      this.log('Ad playback ended. Resuming content...');
      player.src({ src: this.originalSrc, type: 'video/mp4' });
      player.play();
    });
  }

  addSkipButton() {
    const player = this.player;
    const skipButton = document.createElement('button');
    skipButton.textContent = 'Skip Ad';
    skipButton.style.cssText = `
      position: absolute;
      bottom: 20px;
      right: 20px;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      z-index: 1000;
      display: none;
    `;

    player.el().appendChild(skipButton);

    // Show the skip button after the skip offset
    const timer = setTimeout(() => {
      skipButton.style.display = 'block';
      this.log('Skip button displayed.');
    }, this.options.skipOffset * 1000);

    skipButton.addEventListener('click', () => {
      this.log('Ad skipped by user.');
      player.trigger('adskip');
      player.src({ src: this.originalSrc, type: 'video/mp4' });
      player.play();
      skipButton.remove();
      clearTimeout(timer);
    });

    player.on('dispose', () => {
      this.log('Player disposed, cleaning up skip button.');
      clearTimeout(timer);
      skipButton.remove();
    });

    player.on('ended', () => {
      this.log('Ad ended, cleaning up skip button.');
      skipButton.remove();
    });
  }

  log(...args) {
    if (this.options.debug) {
      console.log('[VAST Ads Plugin]', ...args);
    }
  }
}

// Register the plugin with Video.js
videojs.registerPlugin('vastAds', VastAdsPlugin);

export default VastAdsPlugin;
  