import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-title">Oops! Page Not Found</h1>
        <p className="not-found-message">
          The page you're looking for might have been moved, or it never existed.
        </p>
        <Link to="/" className="back-home-button">
          Go Back to Homepage
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
