import React, { useState, useEffect } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import AllPostCard from "./AllPostCard";

function Admin() {
  const { page = 1 } = useParams(); // Extract page from URL, default to 1
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    totalPages: 1,
    currentPage: 1,
    totalPosts: 0,
  });

  // Fetch posts from the API
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
          "https://api.skipthegames.tech/pending-posts",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ page: Number(page) }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setPosts(data.posts || []);
        setFilteredPosts(data.posts || []);
        setPagination({
          totalPages: data.pagination.totalPages,
          currentPage: data.pagination.currentPage,
          totalPosts: data.pagination.totalPosts,
        });
      } catch (err) {
        console.error("Error fetching posts:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [page]);

  // Handle search input changes
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredPosts(posts);
    } else {
      const filtered = posts.filter((post) =>
        post.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredPosts(filtered);
    }
  };

  const onPostAction = async (postId, action, type) => {
    const actionMessages = {
      delete: {
        confirm: "Are you sure you want to delete this post?",
        success: "Post deleted successfully!",
        error: "Failed to delete post. Please try again.",
      },
      approve: {
        confirm: "Are you sure you want to approve this post?",
        success: "Post approved successfully!",
        error: "Failed to approve post. Please try again.",
      },
    };

    const confirmMessage = actionMessages[action]?.confirm;
    const successMessage = actionMessages[action]?.success;
    const errorMessage = actionMessages[action]?.error;

    if (!confirmMessage) {
      console.error("Invalid action type");
      return;
    }

    if (window.confirm(confirmMessage)) {
      try {
        const response = await fetch(
          `https://api.skipthegames.tech/${action === "approve" ? "approve" : "delete-post"}/${postId}/${type}`,
          {
            method: action === "delete" ? "DELETE" : "PUT", // Use PUT for approve action
          }
        );

        if (response.ok) {
          alert(successMessage);

          // Remove the post from state after action
          const updatedPosts = posts.filter((post) => post.id !== postId);
          setPosts(updatedPosts);
          setFilteredPosts(
            updatedPosts.filter((post) =>
              post.title.toLowerCase().includes(searchTerm.toLowerCase())
            )
          );
        } else {
          const errorData = await response.json();
          alert(errorData.message || errorMessage);
        }
      } catch (error) {
        console.error(`Error performing ${action} action:`, error);
        alert(errorMessage);
      }
    }
  };

  return (
    <section id="manage-posts" className="section">
      <h3>Pending Posts</h3>
      <div className="post-list-header">
        <input
          type="text"
          placeholder="Search Here"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Link to="/dashboard/create-post">
          <button id="AddPost">Add Post</button>
        </Link>
      </div>
      <div className="post-list" id="PostList">
        {loading ? (
          <p>Loading posts...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : filteredPosts.length > 0 ? (
          filteredPosts.map((post) => (
            <AllPostCard
              key={post.id}
              post={post}
              onPostAction={onPostAction} // Pass the function here
            />
          ))
        ) : (
          <p>No pending found.</p>
        )}
      </div>

      {pagination.totalPages > 1 && (
        <div className="pagination" id="DashboardPagination">
          <ul>
            {/* Previous Page */}
            <NavLink
              to={`/dashboard/manage-post/${Math.max(1, pagination.currentPage - 1)}`}
            >
              <li className={pagination.currentPage === 1 ? "disabled" : ""}>
                &laquo;
              </li>
            </NavLink>
            {/* Page Numbers */}
            {Array.from({ length: pagination.totalPages }, (_, i) => i + 1).map(
              (num) => (
                <NavLink to={`/dashboard/manage-post/${num}`} key={num}>
                  <li
                    className={pagination.currentPage === num ? "ActiveP" : ""}
                  >
                    {num}
                  </li>
                </NavLink>
              )
            )}
            {/* Next Page */}
            <NavLink
              to={`/dashboard/manage-post/${Math.min(
                pagination.totalPages,
                pagination.currentPage + 1
              )}`}
            >
              <li
                className={
                  pagination.currentPage === pagination.totalPages
                    ? "disabled"
                    : ""
                }
              >
                &raquo;
              </li>
            </NavLink>
          </ul>
        </div>
      )}
    </section>
  );
}

export default Admin;
