import React, { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import Card from "./Card";
import Loader from "./LoadingPage";
function Search() {
  const { query, page = "1" } = useParams(); // Extract query and page from URL
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1); // Track total pages

  // Convert `page` to a number for calculations
  const currentPage = Number(page);

  // Fetch search results when the query or page changes
  useEffect(() => {
    if (query) {
      fetchSearchResults(query, currentPage);
    }
  }, [query, currentPage]);

  // Fetch search results from API
  const fetchSearchResults = async (searchQuery, page) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("https://api.skipthegames.tech/search-post", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: searchQuery, page, limit: 10 }), // Pass page and limit
      });

      if (!response.ok) {
        throw new Error("No posts found for the given topic.");
      }

      const data = await response.json();
      setResults(data.results || []);
      setTotalPages(data.totalPages || 1); // Update total pages

      if (data.results.length === 0) {
        throw new Error("No results found.");
      }
    } catch (error) {
      setError(error.message || "Error fetching posts.");
      setResults([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="searchDiv">
      <div className="main-container">
        <h3 className="show-search">Search Results for : "{query}"</h3>
        <div className="search-results">
          {loading ? (
            <Loader />
          ) : error ? (
            <div className="no-results">
              <p>No results found.</p>
            </div>
          ) : results.length > 0 ? (
            results.map((result) => <Card post={result} key={result.id} />)
          ) : (
            <div className="no-results">
              <p>No post found.</p>
            </div>
          )}
        </div>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="pagination">
          <ul>
            {/* Previous Page Button */}
            <NavLink to={`/search/${query}/${Math.max(1, currentPage - 1)}`}>
              <li className={currentPage === 1 ? "disabled" : ""}>&laquo;</li>
            </NavLink>

            {/* Page Numbers - Limit to 5 */}
            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
              const pageNumber = Math.max(
                1,
                Math.min(
                  totalPages - 4,
                  currentPage - 2
                )
              ) + i;

              if (pageNumber <= totalPages) {
                return (
                  <NavLink to={`/search/${query}/${pageNumber}`} key={pageNumber}>
                    <li className={currentPage === pageNumber ? "ActiveP" : ""}>{pageNumber}</li>
                  </NavLink>
                );
              }
              return null; // Skip invalid page numbers
            })}

            {/* Next Page Button */}
            <NavLink to={`/search/${query}/${Math.min(totalPages, currentPage + 1)}`}>
              <li className={currentPage === totalPages ? "disabled" : ""}>&raquo;</li>
            </NavLink>
          </ul>
        </div>
      )}

    </div>
  );
}

export default Search;
