import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown, faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { v4 as uuidv4 } from "uuid"; // Import uuid

function VideoReactions({ reaction }) {
  const { likes, dislikes, favorites, id ,type} = reaction;
  // States for reactions and user interactions
  const [reactions, setReactions] = useState({
    likes,
    dislikes,
    favorites,
  });

  const [currentReaction, setCurrentReaction] = useState(null); // Tracks the current user reaction

  // Generate or retrieve sessionId
  const getSessionId = () => {
    let sessionId = localStorage.getItem("sessionId");
    if (!sessionId) {
      sessionId = uuidv4(); // Generate a new sessionId
      localStorage.setItem("sessionId", sessionId); // Save it to localStorage
    }
    return sessionId;
  };

  const sessionId = getSessionId();

  // API call to update reaction
  const updateReactionInDatabase = async (newReaction) => {
    try {
      const response = await axios.post("https://api.skipthegames.tech/interact", {
        action: newReaction,
        postId: id,
        sessionId,
        type
      });

      // Update the reaction counts and current reaction
      setReactions(response.data.updatedCounts);
      setCurrentReaction(newReaction);
    } catch (error) {
      console.error("Error updating reaction:", error);
    }
  };

  // Handlers
  const handleReaction = (newReaction) => {
    if (currentReaction === newReaction) {
      // If the same reaction is clicked again, remove the reaction
      updateReactionInDatabase(null);
      setCurrentReaction(null);
    } else {
      // Otherwise, set the new reaction, replacing the previous one
      updateReactionInDatabase(newReaction);
    }
  };

  return (
    <div className="button-group" id="ImagesViewActionBtn">
      <div
        id="likeBtn"
        className={`video-btn ${currentReaction === "like" ? "active" : ""}`}
        onClick={() => handleReaction("like")}
      >
        <FontAwesomeIcon icon={faThumbsUp} />
        <small id="TotalLike">{reactions.likes}</small>
      </div>

      <div
        id="dislikeBtn"
        className={`video-btn ${currentReaction === "dislike" ? "active" : ""}`}
        onClick={() => handleReaction("dislike")}
      >
        <FontAwesomeIcon icon={faThumbsDown} />
        <small id="TotalDislike">{reactions.dislikes}</small>
      </div>

      <div
        id="favoriteBtn"
        className={`video-btn ${currentReaction === "favorite" ? "active" : ""}`}
        onClick={() => handleReaction("favorite")}
      >
        <FontAwesomeIcon icon={faHeart} />
        <small id="TotalFavorites">{reactions.favorites}</small>
      </div>

      <div className="video-btn">
        <FontAwesomeIcon icon={faShare} />
        <small>Share</small>
      </div>
    </div>
  );
}

export default VideoReactions;
