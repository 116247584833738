import React, { useEffect, useRef } from "react";

const AdScriptLoaderAdsTera = ({ scriptKey, scriptSrc, height, width ,ContentID }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            // Clean previous content
            containerRef.current.innerHTML = "";

            // Define the script parameters dynamically
            const atOptions = document.createElement("script");
            atOptions.type = "text/javascript";
            atOptions.innerHTML = `
                atOptions = {
                    'key': '${scriptKey}',
                    'format': 'iframe',
                    'height': ${height || 250},
                    'width': ${width || 300},
                    'params': {}
                };
            `;
            containerRef.current.appendChild(atOptions);

            // Load the invoke.js script
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = scriptSrc;
            script.async = true;
            containerRef.current.appendChild(script);
        }
    }, [scriptKey, scriptSrc, height, width]);

    return <div ref={containerRef} className={ContentID}></div>;
};

export default AdScriptLoaderAdsTera;
