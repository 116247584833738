import React from 'react';
import { RotatingLines } from 'react-loader-spinner';
const Loader = () => {
  return (
    <div className="loader-container">
      {/* Rotating Lines Spinner */}
      <RotatingLines
        strokeColor="red" // Spinner color
        strokeWidth="5" // Spinner width
        animationDuration="1.5" // Spinner animation speed
        width="100" // Spinner width
        visible={true} // Makes the spinner visible
      />
    </div>
  );
};

export default Loader;
