import React, { useEffect } from 'react';

function VideoSliderAds() {
  useEffect(() => {
    // Define the script element
    const script = document.createElement('script');
    script.src = "//trusting-produce.com/d/mnFrzDd.GDletpPW3WpEvMbPmyVsJEZRDm0h1sNRj/cw2TOYTGM/wJLtT-Uz2XNGzqYF5/MWzAEl";
    script.referrerPolicy = "no-referrer-when-downgrade";
    script.async = true;

    // Add settings (if necessary)
    script.settings = {};

    // Append the script to the DOM
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []); // The empty dependency array ensures this runs only once

  return null; // No UI needed for this component
}

export default VideoSliderAds;
