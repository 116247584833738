import React, { useState, useEffect } from "react";
import axios from "axios";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from '@mui/material/Alert';

const SettingsPage = ({ user }) => {
    const profileImage = "https://api.skipthegames.tech/user_images/" + user.profile_image;
    const coverImage = "https://api.skipthegames.tech/user_images/" + user.cover_image;
    const [profilePic, setProfilePic] = useState(profileImage);
    const [coverPic, setCoverPic] = useState(coverImage);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [file, setFile] = useState(null);
    const [type, setType] = useState("");

    const validatePassword = (password) => {
        return password.length >= 8 ? "" : "Password must be at least 8 characters long.";
    };

    useEffect(() => {
        let timeout;
        if (error || success) {
            timeout = setTimeout(() => {
                setError(null);
                setSuccess(null);
            }, 3000);
        }
        return () => clearTimeout(timeout);
    }, [error, success]);

    // Handle file change for profile and cover images
    const handleFileChange = (e, changeType) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setType(changeType);
        }
    };

    useEffect(() => {
        const uploadFile = async () => {
            if (!file || !type) return;

            const formData = new FormData();
            formData.append("image", file);
            const userId = user.id;

            try {
                const response = await axios.post(
                    `https://api.skipthegames.tech/upload/${userId}/${type}`,
                    formData,
                    {
                        headers: { "Content-Type": "multipart/form-data" },
                    }
                );

                if (type === "profile") {
                    setProfilePic("https://api.skipthegames.tech" + response.data.url);
                    setSuccess("Profile picture updated successfully!");
                } else if (type === "cover") {
                    setCoverPic("https://api.skipthegames.tech" + response.data.url);
                    setSuccess("Cover picture updated successfully!");
                }
            } catch (error) {
                console.error(error);
                setError("Failed to upload image.");
            } finally {
                setFile(null); // Clear file after upload
                setType(""); // Clear type after upload
            }
        };

        uploadFile();
    }, [file, type, user.id]);

    // Handle password update
    const handlePasswordUpdate = async (e) => {
        e.preventDefault();

        setError("");
        setSuccess("");

        if (!oldPassword || !newPassword || !confirmPassword) {
            setError("All fields are required.");
            return;
        }

        const validationError = validatePassword(newPassword);
        if (validationError) {
            setError(validationError);
            return;
        }

        if (newPassword !== confirmPassword) {
            setError("New passwords do not match.");
            return;
        }

        try {
            const response = await fetch(
                `https://api.skipthegames.tech/update-password/${user.id}`,
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ oldPassword, newPassword }),
                }
            );

            if (!response.ok) {
                const responseData = await response.json();
                throw new Error(responseData.error || "Failed to update password.");
            }

            setSuccess("Password updated successfully!");
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <section id="manage-posts" className="section">
            <div className="profile-page">
                <div className="cover-container" style={{
                    backgroundImage: `linear-gradient(#00000052, #00000052), url(${coverPic})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}>
                    <label htmlFor="CoverPic" className="cover-pic-label">
                        <FontAwesomeIcon icon={faCamera} />
                    </label>
                    <input
                        type="file"
                        id="CoverPic"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e, "cover")}
                        style={{ display: "none" }}
                    />
                    <div
                        className="profile-container"
                        style={{
                            backgroundImage: `url(${profilePic || profileImage})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        <label htmlFor="ProfilePic" className="profile-pic-label">
                            <FontAwesomeIcon icon={faCamera} />
                        </label>
                        <input
                            type="file"
                            id="ProfilePic"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, "profile")}
                            style={{ display: "none" }}
                        />
                    </div>
                </div>

                <div className="info-password-container">
                    <div className="info-container">
                        <h3>User Information</h3>
                        <p>Username: {user.username}</p>
                        <p>Email: {user.email}</p>
                    </div>
                    <div className="password-container">
                        <h3>Change Password</h3>
                        <form onSubmit={handlePasswordUpdate}>
                            <div className="form-group">
                                <input
                                    type="password"
                                    placeholder="Current Password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    required
                                />
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                                <input
                                    type="password"
                                    placeholder="Confirm New Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit">Update Password</button>
                        </form>
                    </div>
                </div>
                {error && <Alert id="Alert" severity="error">{error}</Alert>}
                {success && <Alert id="Alert" severity="success">{success}</Alert>}
            </div>
        </section>
    );
};

export default SettingsPage;
