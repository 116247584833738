import React, { useEffect } from "react";

const AdScriptLoader = ({ scriptSrc, containerId }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const script = document.createElement("script");
          script.async = true;  
          script.src = scriptSrc;
          script.referrerPolicy = "no-referrer-when-downgrade";
          const container = document.getElementById(containerId);
          if (container) container.appendChild(script);
          observer.disconnect();
        }
      },
      { threshold: 0.1 } // Trigger when 10% is visible
    );

    const container = document.getElementById(containerId);
    if (container) observer.observe(container);

    return () => observer.disconnect();
  }, [scriptSrc, containerId]);

  return (
    <div
      id={containerId}
      style={{
        height: "250px",
        width: "300px"
      }}
    ></div>
  );
};

export default AdScriptLoader;
