import { useParams } from "react-router-dom";
import React, { useRef, useState, useEffect, useContext } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
// Import the custom VAST plugin
import "./AdScripts/custom-vast-plugin"; 
import { PostContext } from "../hook/postContext";
import Loader from "./component/LoadingPage";
import RecommendedCard from "./component/RecommendedCard";
import PostCard from "./component/PostCard";
import VideoReactions from "./component/VideoReactions";
import AdScriptLoader from "./AdScripts/AdScriptLoader";
import AddSchema from "./Schema/AddSchema";
import Tags from "./component/Tags";

function VideoPlayer() {
  const { slug } = useParams();
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { tags } = useContext(PostContext);

  // State management
  const [posts, setPosts] = useState([]);
  const [video, setVideo] = useState("");
  const [rPosts, setRPosts] = useState([]);
  const [videoData, setVideoData] = useState(null);
  const [poster, setPoster] = useState("./images-src/default-poster.png");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // VAST Ads Tag URL
  const vastAdTag = "https://trusting-produce.com/dFm.Fbzjd/GMN/vMZ-GiUw/_eWmc9/udZ/UWlHkDPBTEUL3wM/TDgewvMRT/kitrNxT/cAxGOYDSAHyLM/Al";
  const scriptSrc1 = "https://private-hide.com/bwX.VosgdMGJlq0NYyW/d/ieY/W/5Zu/ZgX/IS/beJmM9xuHZEUUl/k/PKT/Uw2OOLTmAX4fMRDXgIt/NMTmYx5wMmD_gAwpOjQK";
  const scriptSrc2 = "https://private-hide.com/b.XnVcsIdvG_ly0YYZWbdDiLYQWV5huYZ-X/IX/KevmH9VuCZvUclwkVPRTBUb3jMrTPcOz/MWDzEFtvN/THcBxvN_zLMtwlMXgj";

  const fetchVideoData = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://api.skipthegames.tech/video-posts", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ slug }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error: ${response.status}, ${errorText}`);
      }
      const data = await response.json();
      setPosts(data.relatedPosts || []);
      setRPosts(data.similarPosts || []);
      setVideoData(data.videoPost || {});
      setVideo(data.videoUrl);
      setPoster(data.videoPost?.image ? "https://api.skipthegames.tech/post_images/" + data.videoPost.image : "./images/default-poster.png");
    } catch (err) {
      setError(`Error fetching video data: ${err.message}`);
      console.error("Error fetching video data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchVideoData();
    }
  }, [slug]);

  // Render time in a human-readable format
  const renderTimeAgo = (dateString) => {
    if (!dateString) return "Unknown";
    const secondsAgo = Math.floor((Date.now() - new Date(dateString)) / 1000);
    const intervals = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
    ];
    for (const interval of intervals) {
      const count = Math.floor(secondsAgo / interval.seconds);
      if (count > 0) return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
    }
    return "just now";
  };


  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: false,
        preload: "auto",
        muted: true,
        width: "100%",
        height: 360,
        poster: poster,
      });

      playerRef.current.ready(() => {
        playerRef.current.vastAds({
          adTagUrl: vastAdTag,
          debug: true,
          skipOffset: 5, // Show skip button after 5 seconds
          preload: "auto",
        });

        playerRef.current.on("adstart", () => {
          console.log("Ad started");
        });

        playerRef.current.on("adend", () => {
          console.log("Ad ended");
        });

        playerRef.current.on("adskip", () => {
          console.log("Ad skipped");
        });

        playerRef.current.play();
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [video, poster, vastAdTag]);

  
  if (loading) return <Loader />;
  if (error) return <p>Error: {error}</p>;

  const {
    title,
    likes,
    dislikes,
    favorites,
    view_count,
    created_at,
    profile_image,
    username,
    id,
    reaction_percentage,
  } = videoData || {};

  const reaction = { likes, dislikes, favorites, id, type: "video" };
  const video_tags = videoData?.tags || "";
  const tagsArray = video_tags.split(",");

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "BDSHUB",
    url: "https://skipthegames.tech/",
    description: "Exclusive adult videos and content that caters to your desires.",
    publisher: {
      "@type": "Organization",
      name: "BDSHUB - MMS",
      url: "https://skipthegames.tech/",
      logo: {
        "@type": "ImageObject",
        url: "https://skipthegames.tech/images-src/bdshub.png",
      },
    },
    inLanguage: "en",
    keywords: tags,
  };

  return (
    <div>
      <AddSchema data={schemaData} post={videoData} />
      <div className="main-content-container">
        <div className="main-content-left" id="VideoPlayerLeft">
          <div className="example-video-container">
            <video
              ref={videoRef}
              className="video-js vjs-default-skin"
              id="VideoSD"
              controls
              preload="auto"
              data-setup="{}"
            >
              <source src={video} type="video/mp4" />
              <p>Video Playback Not Supported</p>
            </video>
          </div>
          <span id="Vitle">{title}</span>
          <div className="video-footer">
            <div className="video-view-and-other">
              <span id="Views">{view_count || 0} Views</span> |
              <span id="reaction_percentage">{Math.round(reaction_percentage)}%</span> |
              <span id="DateTime">{renderTimeAgo(created_at)}</span>
            </div>
            <VideoReactions reaction={reaction} />
          </div>

          <div className="profile-follow" id="videoprofile">
            <div className="user-profile">
              <div className="user-profile-logo">
                <img src={"https://api.skipthegames.tech/user_images/" + (profile_image || "default.png")} alt="Profile" />
              </div>
              <div className="user-profile-text">
                <span id="username">{username}</span>
                <small>15k Subscribers</small>
              </div>
            </div>
            <div className="user-follow">
              <button>
                <i className="fa fa-rss" aria-hidden="true"></i> Subscribe
              </button>
            </div>
          </div>
          <Tags tags={tagsArray} />
        </div>

        <div className="main-content-right" id="Right__ADS">
          <div id="RightvideoAds">
            <AdScriptLoader scriptSrc={scriptSrc1} containerId={"ads1"} />
            <AdScriptLoader scriptSrc={scriptSrc2} containerId={"ads2"} />
          </div>
        </div>
      </div>

      <div id="videomainContentner">
        <div className="row" id="VideomainContentnerRow">
          <div className="title-div">
            <span>Related Videos</span>
          </div>
          <PostCard posts={posts} />
        </div>
        <div id="Rightvideo">
          <div className="title-div" style={{ padding: "0" }}>
            <span>Recommended Videos</span>
          </div>
          <RecommendedCard posts={rPosts} />
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
