import React, { useState, useEffect } from "react";

function DownloadLinks({ posts }) {
  const links = {
    monetag: [
      "https://chikraighotoops.com/4/8100705",
      "https://chikraighotoops.com/4/8081492",
      "https://chikraighotoops.com/4/8081502",
      "https://chikraighotoops.com/4/8100702",
      "https://chikraighotoops.com/4/8100703",
      "https://chikraighotoops.com/4/8081501",
      "https://chikraighotoops.com/4/8100701",
      "https://chikraighotoops.com/4/7711354",
      "https://chikraighotoops.com/4/8053476",
      "https://chikraighotoops.com/4/8100704",
    ],
    hilltopads: [
      "https://trusting-produce.com/bD3/Vo0rP.3/pDvYbumXV/JDZoD/0e1_NdzaAMzzN/TwgL0oLMTeUO3nMBDLMG1qOgDfUA",
      "https://trusting-produce.com/b.3bV/0mPT3/psvBbxm/VrJ/ZDD/0v1xNBzlIk4xM-TeIO0YLNTMUR3/MRj/g/xKMUjJU_",
      "https://trusting-produce.com/bu3/Vy0.Pt3ppwvQbKmVVdJQZ/Du0/1iN/zEIn4cMjThQtw/LJTAUy3dMgjrg/xENsDaEz",
      "https://trusting-produce.com/b/3.Vp0-P/3ipdv/bZm/V/JoZnDq0x1QN/zVIN4EMMThU/4/LeTXUN3VMmj/gAxeN/TUku",
      "https://trusting-produce.com/b/3/V.0-PU3Mp/vmbKmiVVJgZWDs0d1_NCzsIH4jM/TXYB2iLjTTU/3gM/jGgSxsNUjNcT",
    ],
  };

  const [randomLinks, setRandomLinks] = useState({ second: "", third: "" });

  useEffect(() => {
    // Get random links from the links object
    const getRandomLink = (key) =>
      links[key][Math.floor(Math.random() * links[key].length)];

    setRandomLinks({
      second: getRandomLink("monetag"),
      third: getRandomLink("hilltopads"),
    });
  }, []);

  return (
    <div id="DownloadBtn">
      {/* First download link */}
      <a href={posts.link} target="_blank" rel="noopener noreferrer">
        Download
      </a>

      {/* Second download link */}
      <a href={randomLinks.second} target="_blank" rel="noopener noreferrer">
        Download
      </a>

      {/* Third download link */}
      <a href={randomLinks.third} target="_blank" rel="noopener noreferrer">
        Download
      </a>
    </div>
  );
}

export default DownloadLinks;
