import React, { useEffect } from "react";

const AdBanner = () => {
  useEffect(() => {
    // Create the settings script
    const settingsScript = document.createElement("script");
    settingsScript.type = "text/javascript";
    settingsScript.text = `
      atOptions = {
        'key' : '147db83dd83fdc486eab3d7ccfa434d4',
        'format' : 'iframe',
        'height' : 90,
        'width' : 728,
        'params' : {}
      };
    `;

    // Create the script that loads the ad
    const adScript = document.createElement("script");
    adScript.type = "text/javascript";
    adScript.src = "//conductorhimselfwhipped.com/147db83dd83fdc486eab3d7ccfa434d4/invoke.js";

    // Append both scripts to the div container
    const adContainer = document.getElementById("AdBanner");
    adContainer.appendChild(settingsScript);
    adContainer.appendChild(adScript);

    // Cleanup on unmount
    return () => {
      adContainer.innerHTML = ""; // Remove scripts and ad content
    };
  }, []);

  return (
    <div
      id="AdBanner"
      style={{
        width: "728px",
        height: "90px",
        margin: "0 auto",
        textAlign: "center",
      }}
    ></div>
  );
};

export default AdBanner;
