import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const [imagePosts, setImagePosts] = useState([]);
  const [videoPosts, setVideoPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [imagePagination, setImagePagination] = useState({ totalPages: 1, currentPage: 1, totalPosts: 0 });
  const [videoPagination, setVideoPagination] = useState({ totalPages: 1, currentPage: 1, totalPosts: 0 });
  const fetchPostsTags = async () => {
    setError(null);
    try {
      const response = await axios.post("https://api.skipthegames.tech/get_tags");
      if (response.status === 200) {
        setTags(response.data.tags || []);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchImagePosts = async (topic, page) => {
    setError(null);
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.skipthegames.tech/all-image-posts",
        { topic, page: Number(page) }
      );
      if (response.status === 200) {
        setImagePosts(response.data.posts || []);
        setImagePagination(response.data.pagination || { totalPages: 1, currentPage: 1, totalPosts: 0 });
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchVideoPosts = async (topic, page) => {
    setError(null);
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.skipthegames.tech/all-video-posts",
        { topic, page: Number(page) }
      );
      if (response.status === 200) {
        setVideoPosts(response.data.posts || []);
        setVideoPagination(response.data.pagination || { totalPages: 1, currentPage: 1, totalPosts: 0 });
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPostsTags();
  }, []);

  return (
    <PostContext.Provider value={{
      imagePosts,
      videoPosts,
      loading,
      error,
      imagePagination,
      videoPagination,
      setVideoPagination,
      setImagePagination,
      tags,
      fetchImagePosts,
      fetchVideoPosts,
      setError,
    }}>
      {children}
    </PostContext.Provider>
  );
};
